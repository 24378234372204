<template>
 <Head>
      <title>{{title}}</title>
      <meta name="description" :content="description" />
      <meta name="keywords" :content="keywords" />
    </Head>


  <div class="home-container">

    <SplashContainer :splashImageUrl="splashImage">
    
      <h2 class="splash-title">
  <span style="color: var(--accent);">HEALTHY EATING</span> <br>
  <span style="color: white;">HEALTH KITCHEN</span>
</h2>


<!-- <button role="button" class="btn orderopia-btn btn-white" data-orderopia-area="order"
        data-orderopia-business="health-kitchen" aria-label="order">
        <span>Order Online</span>
      <img alt="arrow right" class="ms-2" style="height:20px;" src="../assets/img/arrow-right-dark.webp">
      </button>  -->

    <router-link to="/order-now" class="mt-4 order-now-btn">
      <img class="order-now-btn-img" src="../assets/img/sticker-btn.webp">

      <div class="d-flex">
        <p class="m-0">Order Now</p>
        <img alt="arrow right" class="ms-2" style="height:20px;" src="../assets/img/arrow-right.webp">
      </div>
     
    </router-link>
    </SplashContainer>

    
    <div class="social-media-container">
      <a href="https://www.facebook.com/p/Health-Kitchen-100072307119603/" target="_blank"><i class="bi bi-facebook"></i></a>
      <a href="https://www.instagram.com/healthkitchenuk?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank"><i class="bi bi-instagram"></i></a>
    </div>

    <!-- Our Story -->
    <div class="d-flex flex-column mt-4 mb-5 align-items-center mx-auto" style="max-width:800px;">
      <h4 class="text-primary">Discover</h4>
      <h2>Our Story</h2>
      <div class="mt-2 header-separator"></div>

      <div class="d-flex mx-4 mx-md-0 align-items-center mt-4 our-story">

        <img alt="our story" class="our-story-img mb-3 mb-md-0 col me-0 me-md-4" src="../assets/img/our-story.webp">

        <div class="col">
          <div class="d-flex our-story-title-container">
            <div class="d-none d-md-block me-2 vertical-separator"></div>
            <h3 class="our-story-title">Welcome to Health Kitchen </h3>
          </div>
          <p class="our-story-content">Welcome to Health Kitchen – your go to destination for delicious and nutritious meals crafted to fuel your body and mind. We’re passionate about healthy eating, offering fresh, wholesome ingredients and balanced options that make staying healthy easy and enjoyable. Eat well, live well!</p>
        </div>

      </div>
    </div>


    <!-- Online Ordering -->
    <div class="online-ordering">
  <RuggedSeparator :isTop="true"/>



   <div class="online-ordering-inner-container">
    <h4 class="text-white">Check out</h4>
    <h2 class="text-white">Online Ordering</h2>
    <div class="mt-2 header-separator"></div>


    <div class="d-flex mt-4 online-ordering-inner-container-content" style="width:100%;">
      <img alt="app example" class="col-4 online-ordering-img"  src="../assets/img/app-example.webp">


      <div class="col-md-8 col-12">
        <p class="app-title title-lg text-white">Start your order!</p>

         <a class="btn btn-white" href="https://app.orderopia.com/health-kitchen/order" aria-label="order">
        <span>Order Online</span>
      <img alt="arrow right" class="ms-2" style="height:20px;" src="../assets/img/arrow-right-dark.webp">
      </a> 

        <p class="my-4 text-white">Or download our bespoke app for an enhanced ordering experience!</p>
        <div class="app-link-container align-self-start d-flex align-items-center">
          <a href="/app-download" class="btn btn-secondary">
            <img alt="google play" class="me-2" style="height:20px;" src="../assets/img/download.webp">
            <span>Download</span>
          </a> 

        </div>
      </div>


    </div>
  
  </div>   

  <RuggedSeparator :isTop="false"/>

</div>

    <!-- Menu Item Images-->
    <div class="menu-item-images">
      <img alt="menu item" src="../assets/img/menu-item-1.webp">
      <img alt="menu item" src="../assets/img/menu-item-2.webp">
      <img alt="menu item" src="../assets/img/menu-item-3.webp">
      <img alt="menu item" src="../assets/img/menu-item-4.webp">
      <img alt="menu item" src="../assets/img/menu-item-5.webp">
      <img alt="menu item" src="../assets/img/menu-item-6.webp">
      <img alt="menu item" src="../assets/img/menu-item-7.webp">
      <img alt="menu item" src="../assets/img/menu-item-8.webp">

    </div>

  </div>
</template>

<script> import RuggedSeparator from '../components/RuggedSeparator.vue'
import SplashContainer from '../components/SplashContainer.vue'

import splash from "../assets/img/splash.webp";
import { Head } from '@vueuse/head';

export default {
  components: {
     RuggedSeparator,
    SplashContainer,
    Head
  },
  data() {
    return {
      title: "Health Kitchen  | Best Healthy Food",
      description: "Fresh food from Health Kitchen . Order now. The food you really want is just one click away. Order healthy food online.",
      keywords: " bromsgrove,  healthy food,  healthy, bromsgrove healthy food",
      splashImage: splash,
      splashSubtitle: "Health Kitchen ",
      splashTitle: "Healthy Food"

    }
  },
  mounted() {
    // eslint-disable-next-line
    initOrderopia();
  }

}

</script>

<style scoped>

.social-media-container {
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  left:25px;
  font-size:30px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Adds 20px space between the children */

}

.social-media-container a {
  color:white;
}


.splash-title {
  font-size:38px;
  font-family: 'MyCustomBoldFont', sans-serif;
      font-weight: 400; 
      text-transform: uppercase;
}


.order-now-btn {
position: relative;
height:45px;
}

.order-now-btn > div {
  height:100%;
  position:absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color:white;
}

.order-now-btn:hover .order-now-btn > div {
  color:red;
}

.order-now-btn-img {
  height:50px;
}

.order-now-btn > div > p {
  font-size:20px;
  white-space: nowrap;
}


.vertical-separator {
  width: 1px;
  height: 100%;
  background-color: black;
}


.our-story-title-container {
  height: 60px;
}



.our-story-title {
  max-width: 240px;
}

.online-ordering {
  position: relative;
  width: 100%;
  background-color: var(--primary-bg);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  padding: 2rem 0 5rem 0;
}


.online-ordering-inner-container {
  max-width: 800px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.online-ordering-inner-container-content {
  flex-direction: row-reverse;
}

.online-ordering-img {
  width: 300px;
  object-fit: contain;
  margin: auto;
}

.menu-item-images {
  overflow-x: hidden;
  overflow-y:hidden;
  margin: 0 auto;
  width: 100%; 
  height: 200px;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.menu-item-images img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  float:none;
}



.app-title {
    max-width:350px;
    padding:0 10px;
    line-height:50px;
  }


@media (max-width:768px) {

  .social-media-container {
  position:absolute;
  bottom: 0;
  top:auto;
  left:25px;
  font-size:30px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Adds 20px space between the children */

}

  .order-now-btn {
height:35px;
}


.order-now-btn-img {
  height:40px;
}


  .app-title {
    max-width:none;
    width:100%;
  }

  .app-link-container {
  display:flex;
  align-items: center;
  justify-content: center;
}
  .our-story {
    flex-direction: column;
  }



  .our-story-img {
    width: 100%;
  }

  .our-story-title-container {
    height: auto;
  }

  .our-story-title {
    max-width: 1000px;
  }


  .online-ordering-inner-container-content {
    flex-direction: column;
    text-align: center;
    align-items: center;

  }

  .online-ordering-inner-container-content .btn-white {
    margin: 0 auto;
  }

 





}

@media (max-width:768px) {

.order-now-btn {
height:42px;
}


.order-now-btn-img {
height:45px;
}
}
</style>
